<template>
  <AppSheetUnitSmall :title="unitShortIdentifier" :subtitle="$t('Amenities')" />
</template>

<script>
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import AppSheetUnitSmall from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetUnitSmall'

export default {
  name: 'UnitPanelSmallContentAmenities',
  components: {
    AppSheetUnitSmall,
  },
  props: {
    projectElement: {
      type: Unit,
      default: () => undefined,
    },
  },
  computed: {
    unit() {
      return this.projectElement
    },
    unitShortIdentifier() {
      return this.unit.shortIdentifier
    },
  },
}
</script>
