import VueDependency from '@/classes/VueDependency'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import LoggingServiceFactory from '@/classes/LoggingService/LoggingServiceFactory'

class SentryVueDependency extends VueDependency {
  // eslint-disable-next-line no-unused-vars
  async install(Vue, router) {
    if (process.env.VUE_APP_SENTRY_DSN !== '') {
      Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_ENV,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          new CaptureConsoleIntegration({
            levels: ['error'],
          }),
        ],
        tracesSampleRate: Number.parseFloat(
          process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE
        ),
        tracingOptions: {
          trackComponents: true,
        },
        denyUrls: [/^chrome-extension:\/\//i],
        // Vue specific
        logErrors: process.env.VUE_APP_SENTRY_LOG_ERRORS === 'true',
        attachProps: true,
      })
    } else {
      const loggingService = new LoggingServiceFactory().makeService()
      loggingService.logInfo('Sentry was not configured')
    }
  }
  get vueGlobalKeyValue() {
    return null
  }
}
export default SentryVueDependency
