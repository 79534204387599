import * as preferenceNames from '@/constants/preferences/preference-names'

export default {
  displayMenuCredits: (state) =>
    state.preferences?.config[preferenceNames.DISPLAY_MENU_CREDITS],
  allowFullscreenNavigation: (state) =>
    state.preferences?.config[preferenceNames.ALLOW_FULLSCREEN_NAVIGATION],
  enableInteractiveBackgroundZoom: (state) =>
    state.preferences?.config[
      preferenceNames.ENABLE_INTERACTIVE_BACKGROUND_ZOOM
    ],
  enableContactForms: (state) =>
    state.preferences?.config[preferenceNames.ENABLE_CONTACT_FORMS],
  enableCheckPriceForm: (state) =>
    state.preferences?.config[preferenceNames.ENABLE_CHECK_PRICE_FORMS],
  displayAvailability: (state) =>
    state.preferences?.config[preferenceNames.DISPLAY_AVAILABILITY],
  displayPrice: (state) =>
    state.preferences?.config[preferenceNames.DISPLAY_PRICE],
  enableUnitPriceQuotation: (state) =>
    state.preferences?.config[preferenceNames.ENABLE_UNIT_PRICE_QUOTATION],
  enableAvailabilityView: (state) =>
    state.preferences?.config[preferenceNames.ENABLE_AVAILABILITY_VIEW],
  enablePricesOnAvailabilityView: (state) =>
    state.preferences?.config[
      preferenceNames.DISPLAY_PRICES_ON_AVAILABILITY_VIEW
    ],
  enableGeneralTour360GalleryView: (state) =>
    state.preferences?.config[
      preferenceNames.ENABLE_GENERAL_TOUR360_GALLERY_VIEW
    ],
  hideQuoterButton: (state) =>
    state.preferences?.config[preferenceNames.HIDE_QUOTER_BUTTON],
}
