import QuoterDateTools from '@/classes/Quoter/QuoterDateTools'

export default class FinancingQuoter {
  constructor(financingSettings, bookingPriceAmount = 0) {
    if (financingSettings == undefined) {
      throw new Error(`FinancingQuoter: financingSettings are required.`)
    }
    if (
      !QuoterDateTools.isIsoStringDateValid(financingSettings.lastPaymentDate)
    ) {
      throw new Error(
        `FinancingQuoter: Invalid lastPaymentDate ${financingSettings.lastPaymentDate}.`
      )
    }
    if (!this._isPercentageValid(financingSettings.initialPaymentPercentage)) {
      throw new Error(
        `FinancingQuoter: Invalid initialPaymentPercentage ${JSON.stringify(
          financingSettings.initialPaymentPercentage
        )}.`
      )
    }
    if (!this._isPercentageValid(financingSettings.deferredPaymentPercentage)) {
      throw new Error(
        `FinancingQuoter: Invalid deferredPaymentPercentage ${JSON.stringify(
          financingSettings.deferredPaymentPercentage
        )}.`
      )
    }
    if (!this._isPriceValid(bookingPriceAmount)) {
      throw new Error(
        `FinancingQuoter: Invalid bookingPriceAmount ${bookingPriceAmount}.`
      )
    }

    const {
      lastPaymentDate,
      initialPaymentPercentage,
      deferredPaymentPercentage,
    } = financingSettings
    this._lastPaymentDate = QuoterDateTools.convertIsoStringDateToDateObject(
      lastPaymentDate
    )
    this._initialPaymentPercentage = initialPaymentPercentage
    this._deferredPaymentPercentage = deferredPaymentPercentage
    this._bookingPriceAmount = bookingPriceAmount
  }

  quote(netPrice, quoteDate) {
    if (!this._isPriceValid(netPrice)) {
      throw new Error(`FinancingQuoter.quote(): invalid netPrice ${netPrice}.`)
    }
    if (!QuoterDateTools.isDateObjectValid(quoteDate)) {
      throw new Error(
        `FinancingQuoter.quote(): invalid quoteDate ${quoteDate}.`
      )
    }

    const initialPaymentAmount =
      netPrice * this._initialPaymentPercentage - this._bookingPriceAmount
    const deferredAmount = netPrice * this._deferredPaymentPercentage
    const finalPaymentAmount =
      netPrice -
      initialPaymentAmount -
      deferredAmount -
      this._bookingPriceAmount
    const monthlyPaymentDates = this._getMonthlyPaymentDates(
      quoteDate,
      this._lastPaymentDate
    )
    const monthlyPaymentsCount = monthlyPaymentDates.length
    const monthlyPaymentAmount = deferredAmount / monthlyPaymentsCount
    const monthlyPayments = monthlyPaymentDates.map((date) => ({
      date,
      amount: monthlyPaymentAmount,
    }))

    return {
      initialPaymentAmount,
      finalPaymentAmount,
      deferredAmount,
      monthlyPaymentsCount,
      monthlyPaymentAmount,
      monthlyPayments,
    }
  }

  _getMonthlyPaymentDates(initialDate, finalDate) {
    const limitPaymentDate = new Date(
      QuoterDateTools.getYear(finalDate),
      QuoterDateTools.getMonth(finalDate)
    )
    const lastMonthValue = 11
    const paymentDates = []
    let paymentYear = QuoterDateTools.getYear(initialDate)
    let paymentMonth = QuoterDateTools.getMonth(initialDate)
    let paymentDate

    do {
      paymentMonth++
      if (paymentMonth > lastMonthValue) {
        paymentMonth = 0
        paymentYear++
      }
      paymentDate = new Date(paymentYear, paymentMonth)
      paymentDates.push(paymentDate)
    } while (paymentDate < limitPaymentDate)

    return paymentDates
  }

  _isPercentageValid(percentageValue) {
    return (
      typeof percentageValue === 'number' &&
      !isNaN(percentageValue) &&
      percentageValue >= 0 &&
      percentageValue <= 1
    )
  }

  _isPriceValid(priceValue) {
    return (
      typeof priceValue === 'number' && !isNaN(priceValue) && priceValue >= 0
    )
  }
}
