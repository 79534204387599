class CollectionSlugs {
  static get PLANS() {
    return 'plans'
  }
  static get PLANS3D() {
    return 'plans-3d'
  }
  static get GALLERY() {
    return 'gallery'
  }
  static get VIEWS() {
    return 'views'
  }
  static get TOUR360() {
    return 'tour360'
  }
  static get VIDEO() {
    return 'video'
  }
  static get all() {
    return [
      CollectionSlugs.GALLERY,
      CollectionSlugs.VIEWS,
      CollectionSlugs.PLANS3D,
      CollectionSlugs.PLANS,
      CollectionSlugs.TOUR360,
      CollectionSlugs.VIDEO,
    ]
  }
  static isValid(slug) {
    return CollectionSlugs.all.some((collectionSlug) => collectionSlug === slug)
  }
}

export default CollectionSlugs
