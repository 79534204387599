import iconNames from '@/constants/ui-kit/standard/icon-names'
import CollectionSlugs from '@/classes/Gallery/CollectionSlugs'

export const galleryCollectionsData = {
  [CollectionSlugs.PLANS3D]: {
    nameTranslationKey: 'Planta 3D',
    iconName: iconNames.UICube3D,
  },
  [CollectionSlugs.PLANS]: {
    nameTranslationKey: 'Planos',
    iconName: iconNames.UILayout,
  },
  [CollectionSlugs.GALLERY]: {
    nameTranslationKey: 'Galería',
    iconName: iconNames.UIGallery,
  },
  [CollectionSlugs.VIEWS]: {
    nameTranslationKey: 'Vistas',
    iconName: iconNames.UICamera,
  },
  [CollectionSlugs.TOUR360]: {
    nameTranslationKey: 'Recorrido',
    iconName: iconNames.LegacyTour360,
  },
  [CollectionSlugs.VIDEO]: {
    nameTranslationKey: 'Video',
    iconName: iconNames.UIVideo,
  },
}
