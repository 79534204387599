import CollectionSlugs from '@/classes/Gallery/CollectionSlugs'

export default class UnitGalleryAdapter {
  static adapt(unitLevel, unitLevelJsonData, viewsGalleryJsonData) {
    this.adaptGeneralGalleryData(unitLevel, unitLevelJsonData)
    this.adaptPlans3dGalleryData(unitLevel, unitLevelJsonData)
    this.adaptPlansGalleryData(unitLevel, unitLevelJsonData)
    this.adaptTour360GalleryData(unitLevel, unitLevelJsonData)
    this.adaptViewsGalleryData(unitLevel, viewsGalleryJsonData)
    this.adaptVimeoVideoGalleryData(unitLevel, unitLevelJsonData)
  }
  static adaptGeneralGalleryData(unitLevel, unitLevelJsonData) {
    const { imagesGallery, finishesGallery } = unitLevelJsonData
    const jsonImagesList = [...imagesGallery, ...finishesGallery]
    const unitLevelGalleryCollection = unitLevel.gallery.collection(
      CollectionSlugs.GALLERY
    )
    this.createGalleryImageResources(unitLevelGalleryCollection, jsonImagesList)
  }
  static adaptPlansGalleryData(unitLevel, unitLevelJsonData) {
    const { dimensionsImgUrl } = unitLevelJsonData
    const resources = this.filterNullResources([
      this.adaptResourceData(dimensionsImgUrl),
    ])

    unitLevel.setGalleryCollection({
      collectionSlug: CollectionSlugs.PLANS,
      resources,
    })
  }
  static adaptPlans3dGalleryData(unitLevel, unitLevelJsonData) {
    const { floorPlans3dImgUrl } = unitLevelJsonData
    const resources = this.filterNullResources([
      this.adaptResourceData(floorPlans3dImgUrl),
    ])

    unitLevel.setGalleryCollection({
      collectionSlug: CollectionSlugs.PLANS3D,
      resources,
    })
  }
  static adaptVimeoVideoGalleryData(unitLevel, unitLevelJsonData) {
    const { vimeoVideoUrl } = unitLevelJsonData

    const resources = this.filterNullResources([
      this.adaptResourceData(vimeoVideoUrl),
    ])

    unitLevel.setGalleryCollection({
      collectionSlug: CollectionSlugs.VIDEO,
      resources,
    })
  }
  static adaptTour360GalleryData(unitLevel, unitLevelJsonData) {
    const { tour360Url } = unitLevelJsonData
    const resources = this.filterNullResources([
      this.adaptResourceData(tour360Url),
    ])

    unitLevel.setGalleryCollection({
      collectionSlug: CollectionSlugs.TOUR360,
      resources,
    })
  }
  static adaptViewsGalleryData(unitLevel, viewsGalleryData) {
    const jsonImagesList = [...viewsGalleryData]
    const unitLevelViewsCollection = unitLevel.gallery.collection(
      CollectionSlugs.VIEWS
    )
    this.createGalleryImageResources(unitLevelViewsCollection, jsonImagesList)
  }
  static createGalleryImageResources(galleryCollection, jsonImagesList) {
    jsonImagesList.forEach((item) => {
      const resourceData = this.adaptResourceData(item.imgUrl)
      if (resourceData) {
        const createdResource = galleryCollection.createResource(resourceData)
        if (item.animationVideoUrl) {
          createdResource.setAnimationLayerVideoUrl(item.animationVideoUrl)
        }
      }
    })
  }
  static adaptResourceData(resourceUrl, hintText = '', labelText = '') {
    if (!resourceUrl) {
      return null
    }
    return {
      resourceUrl,
      hintText,
      labelText,
    }
  }
  static filterNullResources(resourceDataArray) {
    if (!Array.isArray(resourceDataArray)) {
      throw new Error(
        `UnitGalleryAdapter.filterInvalidResources(): Invalid resourceDataArray ${resourceDataArray}.`
      )
    }
    return resourceDataArray.filter((resource) => {
      return resource !== null
    })
  }
}
