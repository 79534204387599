<template>
  <AppSheetUnitLarge
    :preview-image-url="previewImageUrl"
    :title="unitShortIdentifier"
    :subtitle="$t('Amenities')"
    :enter-to="unitEnterToLink"
    :tour360-to="unitTour360ToLink"
    :features="unitFeatures"
    :brochure-file-name="brochureFileName"
    :brochure-url="unitBrochureFileName"
    :with-disclaimer-button="!!disclaimer"
    :share-url="unitShareUrl"
    @open-disclaimer="showDisclaimer"
  />
</template>

<script>
import AppSheetUnitLarge from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetUnitLarge'

import responsiveMixin from '@/mixins/responsiveMixin'
import { mapGetters } from 'vuex'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import Level from '@/classes/ProjectStructure/Level'
import Tower from '@/classes/ProjectStructure/Tower'
import NeighbourhoodSubdivision from '@/classes/ProjectStructure/NeighbourhoodSubdivision'
import sizes from '@/constants/ui-kit/standard/sizes'
import modalSupportedComponents from '@/constants/ui-kit/standard/modal/modal-supported-components'
import UnitAccessRouterLinks from '@/classes/UnitAccessRouterLinks'

export default {
  name: 'UnitPanelLargeContentAmenities',
  components: {
    AppSheetUnitLarge,
  },
  mixins: [responsiveMixin],
  props: {
    projectElement: {
      type: Unit,
      default: () => undefined,
    },
    accessLinksEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enterButtonSize: sizes.SM,
    }
  },
  computed: {
    ...mapGetters({
      // FIXME: Consider adding another field with the project name only outside the context of contactData
      contactData: 'Project/contactData',
      disclaimer: 'Project/disclaimer',
      isOfflineModeEnabled: 'Project/isOfflineModeEnabled',
    }),
    unit() {
      return this.projectElement
    },
    previewImageUrl() {
      return this.unit.previewImageUrl
    },
    unitShortIdentifier() {
      return this.unit.shortIdentifier
    },
    unitFeatures() {
      return this.unit.features
    },
    unitBrochureFileName() {
      return this.unit.brochureUrl
    },
    unitAccessRouterLinks() {
      try {
        return new UnitAccessRouterLinks(this.unit, this.$loggingService)
      } catch (error) {
        this.$loggingService.logError(
          `UnitPanelLargeContent.unitAccessRouterLinks: error creating UnitAccessRouterLinks. ${error.toString()}`
        )
        return null
      }
    },
    unitEnterToLink() {
      return this.accessLinksEnabled
        ? this.unitAccessRouterLinks.default
        : undefined
    },
    unitTour360ToLink() {
      return this.accessLinksEnabled
        ? this.unitAccessRouterLinks.tour360
        : undefined
    },
    unitTowerName() {
      if (!this.$projectType.is([this.$projectType.MULTITOWER_TYPE])) {
        return undefined
      }

      const currentLevel = this.unit.parent()
      if (!(currentLevel instanceof Level)) {
        this.$loggingService.logError(
          'UnitPanelLargeContent.unitTowerName(): currentLevel is not an instance of Level class'
        )
        return undefined
      }
      const currentTower = currentLevel.parent()
      if (!(currentTower instanceof Tower)) {
        this.$loggingService.logError(
          'UnitPanelLargeContent.unitTowerName(): currentTower is not an instance of Tower class'
        )
        return undefined
      }

      return currentTower.name
    },
    unitSubdivisionName() {
      if (!this.$projectType.is([this.$projectType.NEIGHBOURHOOD_TYPE])) {
        return undefined
      }

      const currentSubdivision = this.unit.parent()

      if (!(currentSubdivision instanceof NeighbourhoodSubdivision)) {
        this.$loggingService.logError(
          'UnitPanelLargeContent.unitSubdivisionName(): currentSubdivision is not an instance of NeighbourhoodSubdivision class'
        )
        return undefined
      }

      return currentSubdivision.name
    },
    unitLevelName() {
      if (
        !this.$projectType.is([
          this.$projectType.SINGLETOWER_TYPE,
          this.$projectType.MULTITOWER_TYPE,
        ])
      ) {
        return undefined
      }
      const currentLevel = this.unit.parent()

      if (!(currentLevel instanceof Level)) {
        this.$loggingService.logError(
          'UnitPanelLargeContent.unitLevelName(): currentLevel is not an instance of Level class'
        )
        return undefined
      }

      return currentLevel.name
    },
    brochureFileName() {
      return this.$t(this.unit.brochureFileNameLocaleKey, {
        unitId: this.unit.label,
        name: this.unit.name,
        level: this.unitLevelName,
        projectName: this.contactData.projectName,
        tower: this.unitTowerName,
        subdivision: this.unitSubdivisionName,
      })
    },
    unitShareUrl() {
      if (this.isOfflineModeEnabled) {
        return undefined
      }

      try {
        const routerLinks = new UnitAccessRouterLinks(
          this.unit,
          this.$loggingService
        )
        const { href } = this.$router.resolve(routerLinks.default)
        return new URL(href, document.baseURI).href
      } catch (error) {
        this.$loggingService.logError(
          `UnitPanelLargeContent.unitShareUrl: error creating UnitAccessRouterLinks. ${error.toString()}`
        )
      }

      return null
    },
  },
  methods: {
    showDisclaimer() {
      this.$store.dispatch('ModalLauncher/launchModal', {
        component: modalSupportedComponents.AppDisclaimerModal,
      })
    },
  },
}
</script>
